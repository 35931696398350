<template>
    <v-dialog
        :value="dialog"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
        max-width="700">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step step="1">
                    Copy invite code
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2">
                    Bind bot
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                    Finish
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-container>
                        <v-row class="flex-column" no>
                            <v-col cols="12" align="center" class="my-8">
                                <template v-if="hasTelegramUser">
                                    <span>
                                    Copy this code:
                                    </span>
                                    <br>
                                    <span class="text-h6">{{ telegram_user.invite_code }}</span>
                                    <v-btn icon @click="copyCode">
                                        <v-icon>
                                            mdi-content-copy
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </template>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        :disabled="!hasTelegramUser"
                                        @click="step = 2">
                                        Next
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-container>
                        <v-row class="flex-column">
                            <v-col cols="12" align="center" class="my-8">
                                Send that code to bot:
                                <br>
                                <a class="text-h6"
                                   href="https://t.me/applications_checker_bot"
                                   target="_blank">
                                    @applications_checker_bot
                                </a>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-btn
                                        text
                                        @click="step = 1">
                                        Back
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        @click="moveToFinalStep">
                                        Next
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-container>
                        <v-row class="flex-column">
                            <v-col cols="12" align="center" class="my-8">
                                <template v-if="hasConnectedTelegramUser">
                                    <span class="text-h4">All done!</span>
                                    <br>
                                    <span class="text-h5">Integration completed successfully!</span>
                                </template>
                                <template v-else>
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </template>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        :disabled="!hasConnectedTelegramUser"
                                        @click="closeDialog">
                                        Done
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>

<script>

import {repository} from "@/api/repository";

export default {
    name: "telegram-integration-dialog",
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        telegram_user: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        step: 1
    }),
    methods: {
        closeDialog() {
            this.$emit("update:dialog", false)
        },
        copyCode() {
            navigator.clipboard.writeText(this.telegram_user.invite_code);
            this.$store.commit("showSnack", {
                text: "The code has been copied!",
                color: "green"
            })
        },
        moveToFinalStep() {
            this.step = 3
            repository.checkTelegramIntegration()
        }
    },
    computed: {
        hasTelegramUser() {
            return this.telegram_user !== null
        },
        hasConnectedTelegramUser() {
            return this.hasTelegramUser && this.telegram_user.chat_id !== null
        }
    },
    watch: {
        dialog(v) {
            if (v === true && this.telegram_user === null) {
                repository.generateTelegramUser()
            }
        }
    }
}
</script>

<style scoped>

</style>

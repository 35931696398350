<template>
    <v-navigation-drawer
        right
        app>
        <v-container>
            <v-row class="flex-column">
                <v-col>
                    <v-btn block color="primary" @click="showAddApplicationDialog">
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        new application
                    </v-btn>
                </v-col>
                <v-divider></v-divider>
                <v-subheader>
                    <v-icon>mdi-filter-variant</v-icon>
                    Filters
                </v-subheader>
                <v-col>
                    <v-text-field
                        v-model="application_filters.application"
                        dense
                        clearable
                        label="Application">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="application_filters.store"
                        :items="stores"
                        dense
                        clearable
                        auto-select-first
                        label="Store"
                        item-value="abbr"
                        item-text="store"
                        placeholder="Choose the store">
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="application_filters.geo"
                        :items="geos"
                        clearable
                        dense
                        auto-select-first
                        label="Geo"
                        placeholder="Choose the geo"
                        item-text="country_code"
                        item-value="id">
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.country_code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.country_title }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="application_filters.buyer"
                        :items="buyers"
                        dense
                        clearable
                        auto-select-first
                        label="Buyer"
                        placeholder="Choose the buyer"
                        item-text="full_name"
                        item-value="id">
                        <template v-slot:item="data">
                            <v-list-item-avatar>
                                <img :src="data.item.photo">
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ data.item.first_name }} {{
                                        data.item.last_name
                                    }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{
                                        data.item.username
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="application_filters.is_banned"
                        :items="ban_statuses"
                        dense
                        clearable
                        auto-select-first
                        label="Status"
                        item-value="state"
                        item-text="title"
                        placeholder="Choose the status">
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-btn block outlined color="primary" @click="filterApps">
                        filter
                    </v-btn>
                </v-col>
                <v-divider></v-divider>
                <v-subheader>
                    <v-icon>mdi-sort-variant</v-icon>
                    Sort
                </v-subheader>
                <v-col></v-col>
            </v-row>
        </v-container>
        <add-application-dialog :dialog.sync="dialogAddApplicationShown"></add-application-dialog>
    </v-navigation-drawer>
</template>

<script>
import AddApplicationDialog from "@/views/applications/released/AddApplicationDialog";
import {repository} from "@/api/repository";
import {stores} from "@/views/applications/stores";

export default {
    name: "ApplicationsFilterNavBar",
    components: {AddApplicationDialog},
    data() {
        return {
            dialogAddApplicationShown: false,
            ban_statuses: [
                {
                    title: "Banned",
                    state: true,
                },
                {
                    title: "Alive",
                    state: false
                }
            ],
            stores: stores
        }
    },
    methods: {
        showAddApplicationDialog() {
            this.dialogAddApplicationShown = true
        },
        filterApps() {
            repository.fetchApplicationsForPage(1)
        },
        handleQueryParams() {
            this.$store.commit("setFilterBuyer", parseInt(this.$route.query.buyer) || null)
            this.$store.commit("setFilterGeo", parseInt(this.$route.query.geo) || null)
            let is_banned = null
            if (this.$route.query.is_banned === 'true') {
                is_banned = true
            } else if (this.$route.query.is_banned === 'false') {
                is_banned = false
            } // otherwise it's null
            this.$store.commit("setFilterStore", this.$route.query.store || null)
            this.$store.commit("setFilterIsBanned", is_banned)
        }
    },
    mounted() {
        repository.fetchGeos()
        repository.fetchBuyers()
        this.handleQueryParams()
    },
    computed: {
        applications() {
            return this.$store.state.applications
        },
        geos() {
            return this.$store.state.geos
        },
        buyers() {
            return this.$store.state.buyers
        },
        application_filters() {
            return this.$store.state.application_filters
        }
    },
    watch: {
        application_filters: {
            handler(filters) {
                // this just remove null-fields
                // eslint-disable-next-line no-unused-vars
                let filter_query_params = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null))
                this.$router.replace({
                    query: filter_query_params
                })
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <v-sheet elevation="4" :color="application.released ? 'green lighten-5' : ''">
        <v-container>
            <v-row>
                <v-col cols="3" class="d-flex flex-column justify-space-around">
                    <span class="font-weight-light text-body-2 text--secondary">
                        <v-icon class="mr-2">{{ application.store === "GP" ? "mdi-android" : "mdi-apple" }}</v-icon>
                        <a :href=appLink
                           target="_blank"
                           class="text--secondary text-decoration-none">{{ application.package }}
                        </a>
                    </span>
                </v-col>
                <v-col cols="3" class="d-flex flex-column justify-space-around">
                    <span v-if="application.released === null"
                          class="align-self-center text--secondary text-caption">
                        Moderating since <b>{{ formattedCreationTime }} ({{ moderatingTime }} days)</b>
                    </span>
                    <template v-else>
                        <span class="align-self-center text--secondary text-caption">
                            <b>Released:</b> {{ formattedReleaseTime }} ({{ formattedDaysSinceRelease }})
                        </span>
                    </template>
                </v-col>
                <v-spacer/>
                <v-col cols="auto" align-self="center">
                    <v-btn
                        text
                        v-if="application.released !== null"
                        color="primary"
                        :loading="release_request"
                        @click="releaseApp">
                        <v-icon left>
                            mdi-bell
                        </v-icon>
                        Start checking
                    </v-btn>
                    <v-btn
                        text
                        color="red"
                        :loading="delete_request"
                        @click="deleteApp">
                        <v-icon left>
                            mdi-trash-can-outline
                        </v-icon>
                        Delete
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

    </v-sheet>
</template>

<script>
import {repository} from "@/api/repository";
import router from "@/router";

const MS_PER_DAY = 1000 * 60 * 60 * 24
const FORMAT_OPTIONS = {year: '2-digit', month: '2-digit', day: '2-digit'};

export default {
    name: "edit-moderating-application-component",
    props: {
        application: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            release_request: false,
            delete_request: false
        }
    },
    methods: {
        getDateWithoutTime(date) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        },
        isTheSameDay(date1, date2) {
            return this.getDateWithoutTime(date1) === this.getDateWithoutTime(date2)
        },
        dateTo2DigitsFormat(date) {
            return new Date(date).toLocaleDateString("ru-RU", FORMAT_OPTIONS)
        },
        countOfDaysBetweenDates(date1, date2) {
            const firstDate = this.getDateWithoutTime(date1)
            const secondDate = this.getDateWithoutTime(date2)
            return Math.floor((secondDate - firstDate) / MS_PER_DAY)
        },
        formatDaysSinceDate(date) {
            const releaseDate = new Date(date)
            const today = new Date()
            if (releaseDate.isTheSameDay) {
                return "today"
            }
            const diff = this.countOfDaysBetweenDates(releaseDate, today)
            if (diff === 1) {
                return "yesterday"
            }
            return diff + " days ago"
        },
        releaseApp() {
            this.release_request = true
            repository.createApplication(
                {
                    'package': this.application.package,
                    'store': this.application.store
                }
            )
                .then(response => {
                    repository.deleteModeratingApplication(this.application.id)
                    .finally(() => {
                        router.push({name: 'ApplicationEdit', params: {id: response.data.id}})
                    })
                })
                .finally(() => {
                    this.release_request = false
                })
        },
        deleteApp() {
            this.delete_request = true
            repository.deleteModeratingApplication(this.application.id)
                .finally(() => {
                    this.delete_request = false
                })
        },
    },
    computed: {
        moderatingTime() {
            return this.countOfDaysBetweenDates(new Date(this.application.created_at), new Date())
        },
        formattedReleaseTime() {
            return this.dateTo2DigitsFormat(this.application.released)
        },
        formattedCreationTime() {
            return this.dateTo2DigitsFormat(this.application.created_at)
        },
        formattedDaysSinceRelease() {
            return this.formatDaysSinceDate(this.application.released)
        },
        appLink() {
            return (this.application.store === "GP" ?
                "https://play.google.com/store/apps/details?id=" :
                "https://apps.apple.com/app/id") + this.application.package
        }
    }
}
</script>

<style lang="stylus" scoped>
//.v-chip.v-chip--small .v-avatar {
//    height: $chip-small-height !important;
//    width: $chip-small-height !important;
//}
</style>

<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].background}">
        <router-view name="navigation"></router-view>
        <router-view name="navigationRight"></router-view>
        <v-main>
            <v-container style="height: 100%">
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-snackbar
            v-model="snackbar_show"
            transition="scroll-y-reverse-transition"
            right
            bottom
            :color="snackbar_color"
            outlined>
            {{ snackbar_text }}
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data: () => ({
        snackbar_show: false,
        snackbar_color: "primary",
        snackbar_text: ""
    }),
    created() {
        this.$store.subscribe((mutation, state) => {
            // console.log("subscribe triggered " + this.snackbar_show)
            if (mutation.type === "showSnack") {
                this.snackbar_text = state.snackbar.text
                this.snackbar_color = state.snackbar.color
                this.snackbar_show = true
            }
        })
    },
    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        }
    }
};
</script>
<template>
    <v-dialog :value="dialog"
              @click:outside="closeDialog"
              @keydown.esc="closeDialog"
              v-if="application"
              max-width="700">
        <v-card outlined>
            <v-form
                ref="application"
                v-model="valid"
                @submit.prevent="">
                <v-card-text>
                    <v-row>
                        <v-col cols="auto" align-self="center">
                            <v-img
                                :src="application.icon"
                                style="border-radius: 10%"
                                width="80"
                                height="80"
                                aspect-ratio="1"/>
                        </v-col>
                        <v-col cols="fill" class="d-flex flex-column justify-space-between ps-0">
                            <span class="font-weight-medium text-subtitle-1">{{ application.title }}</span>
                            <span class="font-weight-light text-body-2 text--secondary">{{ application.package }}</span>
                            <star-sheet :geoRating="application.ratings[0]"></star-sheet>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="d-flex flex-column">
                            <span
                                v-if="application.ban_time !== null">
                                <b>Lifetime:</b> {{ formattedReleaseTime }}–{{ formattedBanTime }} ({{ lifetime }} days)
                            </span>
                            <template v-else>
                                <span>
                                    <b>Release:</b> {{ formattedReleaseTime }} ({{ formattedDaysSinceRelease }})
                                </span>
                                <span>
                                    <b>Update:</b> {{ formattedUpdateTime }} ({{ formattedDaysSinceUpdate }})
                                </span>
                            </template>
                        </v-col>
                    </v-row>
                    <v-autocomplete
                        v-model="application.geos"
                        :items="geos"
                        outlined
                        chips
                        class="mt-4"
                        label="Geos"
                        placeholder="Enter list of geos"
                        hint="Might be empty"
                        multiple
                        item-text="country_code"
                        item-value="id"
                        deletable-chips>

                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeGeo(data.item)">
                                {{ data.item.country_code }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.country_code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.country_title }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                    <v-autocomplete
                        v-model="application.buyers"
                        :items="buyers"
                        outlined
                        chips
                        label="Buyers"
                        placeholder="Enter list of buyers"
                        multiple
                        item-text="last_name"
                        item-value="id"
                        deletable-chips>
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeBuyer(data.item)">
                                <v-avatar left>
                                    <v-img :src="data.item.photo"></v-img>
                                </v-avatar>
                                {{ data.item.first_name }} {{ data.item.last_name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar>
                                    <img :src="data.item.photo">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.first_name }} {{
                                            data.item.last_name
                                        }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.username }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <span>
                        <input type="checkbox" id="jack" v-model="application.is_competitor"> <b>Competitor</b>
                    </span>
                    <br>
                    <span class="subheading">Minimal rating to alert in <v-icon small>mdi-slack</v-icon>Slack</span>
                    <v-row dense>
                        <v-col>
                            <v-chip-group
                                v-model="application.rating_to_slack_alert">
                                <v-chip
                                    active-class="primary"
                                    v-for="rating in predefinedRatings"
                                    :key="rating"
                                    :value="rating">
                                    {{ rating }} ★
                                </v-chip>
                            </v-chip-group>
                        </v-col>
                        <v-col cols="auto" class="mt-3 me-2">
                            <span>or</span>
                        </v-col>
                        <v-col>
                            <v-text-field
                                class="mt-1"
                                dense
                                v-model="application.rating_to_slack_alert"
                                label="Rating"
                                :rules="rules.ratingForAlert"
                                outlined>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        text
                        :disabled="loading"
                        :loading="loading"
                        color="red"
                        @click="deleteApplication">
                        <v-icon left>
                            mdi-delete
                        </v-icon>
                        delete
                    </v-btn>
                    <v-btn
                        text
                        v-if="application.ban_time !== null"
                        :disabled="loading"
                        :loading="loading"
                        color="primary"
                        @click="restoreApplication">
                        <v-icon left>
                            mdi-backup-restore
                        </v-icon>
                        restore
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!valid || loading"
                        :loading="loading"
                        color="primary"
                        @click="saveApplication">
                        <v-icon left>
                            mdi-content-save
                        </v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import {repository} from "@/api/repository";
import StarSheet from "@/views/applications/released/StarSheet";
import router from "@/router";

const predefinedRatings = [4.5, 4.0, 3.5, 3.0, 2.5, 2.0]
const MS_PER_DAY = 1000 * 60 * 60 * 24
const FORMAT_OPTIONS = {year: '2-digit', month: '2-digit', day: '2-digit'};

export default {
    name: "edit-application-dialog",
    components: {StarSheet},
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        application: {
            required: false
        }
    },
    data: () => ({
        valid: false,

        loading: false,
        appPackageErrors: [],

        predefinedRatings: predefinedRatings,

        rules: {
            ratingForAlert: [
                val => (val || '').length === 0 || (!isNaN(val) && val <= 5 && val >= 1) || 'Must be a number between 1 and 5, for example 3.42'
            ],
        }
    }),
    methods: {
        closeDialog() {
            router.back()
            this.$emit("update:dialog", false)
        },
        saveApplication() {
            const requestData = {
                buyers: this.application.buyers,
                geos: this.application.geos,
                is_competitor: this.application.is_competitor,
                rating_to_slack_alert: this.application.rating_to_slack_alert || null
            }
            this.loading = true
            repository.patchApplication(this.application.id, requestData)
                .then(response => {
                    response
                    this.loading = false
                    this.$refs.application.reset()
                    this.closeDialog()
                })
                .catch(error => {
                    this.loading = false
                    return error
                })
        },
        deleteApplication() {
            repository.deleteApplication(this.application.id)
                .then(response => {
                    response
                    this.loading = false
                    this.$refs.application.reset()
                    this.closeDialog()
                })
                .catch(error => {
                    this.loading = false
                    return error
                })
        },
        restoreApplication() {
            repository.restoreApplication(this.application.id)
                .then(response => {
                    response
                    this.loading = false
                    this.$refs.application.reset()
                    this.closeDialog()
                })
                .catch(error => {
                    this.loading = false
                    return error
                })
        },
        getDateWithoutTime(date) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        },
        isTheSameDay(date1, date2) {
            return this.getDateWithoutTime(date1) === this.getDateWithoutTime(date2)
        },
        dateTo2DigitsFormat(date) {
            return new Date(date).toLocaleDateString("ru-RU", FORMAT_OPTIONS)
        },
        countOfDaysBetweenDates(date1, date2) {
            const firstDate = this.getDateWithoutTime(date1)
            const secondDate = this.getDateWithoutTime(date2)
            return Math.floor((secondDate - firstDate) / MS_PER_DAY)
        },
        formatDaysSinceDate(date) {
            const releaseDate = new Date(date)
            const today = new Date()
            if (releaseDate.isTheSameDay) {
                return "today"
            }
            const diff = this.countOfDaysBetweenDates(releaseDate, today)
            if (diff === 1) {
                return "yesterday"
            }
            return diff + " days ago"
        },
        removeBuyer(buyer) {
            this.application.buyers.splice(this.application.buyers.indexOf(buyer.id), 1)
        },
        removeGeo(geo) {
            this.application.geos.splice(this.application.geos.indexOf(geo.id), 1)
        },
    },
    computed: {
        lifetime() {
            return this.countOfDaysBetweenDates(new Date(this.application.released), new Date(this.application.ban_time))
        },
        formattedReleaseTime() {
            return this.dateTo2DigitsFormat(this.application.released)
        },
        formattedUpdateTime() {
            return this.dateTo2DigitsFormat(this.application.last_update)
        },
        formattedBanTime() {
            return this.dateTo2DigitsFormat(this.application.ban_time)
        },
        formattedDaysSinceRelease() {
            return this.formatDaysSinceDate(this.application.released)
        },
        formattedDaysSinceUpdate() {
            return this.formatDaysSinceDate(this.application.last_update)
        },

        geos() {
            return this.$store.state.geos
        },
        buyers() {
            return this.$store.state.buyers
        }
    },
    mounted() {
        repository.fetchGeos()
        repository.fetchBuyers()
    },
}
</script>

<style scoped>

</style>
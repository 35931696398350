<template>
    <v-navigation-drawer
        permanent
        expand-on-hover
        class="primary"
        dark
        app>
        <v-list nav dense>
            <v-list-item link @click="navigateToApplications">
                <v-list-item-icon>
                    <v-icon>mdi-apps</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Applications</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="navigateToModeratingApplications">
                <v-list-item-icon>
                    <v-icon>mdi-shield-sword-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Moderating</v-list-item-title>
            </v-list-item>
        </v-list>

        <template v-slot:append v-if="user">
            <v-divider></v-divider>

            <v-list>
                <v-list-item class="px-2" link @click="navigationToProfile">
                    <v-list-item-avatar>
                        <v-img :src=user.photo></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </template>

    </v-navigation-drawer>
</template>

<script>
import {repository} from "@/api/repository";

export default {
    name: "NavigationLayout",
    methods: {
        navigateToApplications() {
            this.$router.push({name: 'Applications'})
        },
        navigateToModeratingApplications() {
            this.$router.push({name: 'ModeratingApplications'})
        },
        navigationToProfile() {
            this.$router.push({name: 'Profile'})
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    mounted() {
        repository.fetchProfile()

    }
}
</script>

<style scoped>
</style>

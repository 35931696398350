<template>
    <v-container class="pt-0">
        <v-row dense>
            <template v-if="released_applications.length !== 0">
                <v-col cols="12" class="pa-0">
                    <v-subheader>Released apps</v-subheader>
                </v-col>
                <v-col
                    cols="12"
                    v-for="(application, index) in released_applications"
                    :key="'released_' + index">
                    <moderating-application-component
                        :application="application"
                        v-on:start-checking-pressed="startCheckingApplication(application)">
                    </moderating-application-component>
                </v-col>
            </template>

            <template v-if="moderating_applications.length !== 0">
                <v-col cols="12" class="pa-0">
                    <v-subheader>Moderating apps</v-subheader>
                </v-col>
                <v-col
                    cols="12"
                    v-for="(application, index) in moderating_applications"
                    :key="'moderating_' + index">
                    <moderating-application-component
                        :application="application">
                    </moderating-application-component>
                </v-col>
            </template>
            <template v-if="applications.length === 0">
                <v-col
                    cols="12"
                    class="my-4">
                    <span class="text--secondary text-h5 justify-center d-flex">No apps</span>
                </v-col>
            </template>
            <v-col>
                <v-pagination
                    class="mt-4"
                    v-model="page"
                    :length="pages">
                </v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ModeratingApplicationComponent from "@/views/applications/moderating/ModeratingApplicationComponent";
import {PAGE_SIZE, repository} from "@/api/repository";
// import router from "@/router";

export default {
    name: "moderating-application",
    components: {ModeratingApplicationComponent},
    data() {
        return {
            page: 1,
        }
    },
    computed: {
        applications() {
            return this.$store.state.moderating_applications
        },
        pages() {
            return Math.ceil(this.$store.state.moderating_applications_count / PAGE_SIZE)
        },
        released_applications() {
            return this.$store.state.moderating_applications.filter(application => {
                return application.released !== null
            })
        },
        moderating_applications() {
            return this.$store.state.moderating_applications.filter(application => {
                return application.released === null
            })
        },
    },
    mounted() {
        repository.fetchModeratingApplicationsForPage(this.$store.state.moderating_page)
    },
    watch: {
        page: function(page) {
            this.$store.commit("setModeratingPage", page)
            repository.fetchModeratingApplicationsForPage(page)
        }
    }
}
</script>

<style scoped>

</style>
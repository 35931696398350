<template>
  <div></div>
<!--    <v-row justify="center" align="center" style="height: 100%">-->
<!--        <v-col cols="12" sm="8" md="6" lg="5" xl="4">-->
<!--            <v-card class="elevation-12">-->
<!--                <v-toolbar color="primary" dark flat>-->
<!--                    <v-toolbar-title>Авторизация</v-toolbar-title>-->
<!--                </v-toolbar>-->
<!--                <v-card-text>-->
<!--                    <v-form v-model="valid">-->
<!--                        <v-text-field v-model="username"-->
<!--                                      name="login"-->
<!--                                      label="Логин"-->
<!--                                      prepend-icon="mdi-account"-->
<!--                                      required-->
<!--                                      :rules="rules"></v-text-field>-->
<!--                        <v-text-field v-model="password"-->
<!--                                      name="password"-->
<!--                                      prepend-icon="mdi-lock"-->
<!--                                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"-->
<!--                                      :rules="rules"-->
<!--                                      :type="showPassword ? 'text' : 'password'"-->
<!--                                      label="Пароль"-->
<!--                                      required-->
<!--                                      placeholder=" "-->
<!--                                      @click:append="showPassword = !showPassword"></v-text-field>-->
<!--                    </v-form>-->
<!--                </v-card-text>-->
<!--                <v-card-actions>-->
<!--                    <v-spacer></v-spacer>-->
<!--                    <v-btn color="accent" :disabled="!valid" @click="login()">Войти</v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-col>-->
<!--    </v-row>-->
</template>

<script>
    import {httpClient} from "@/api/httpClient"
    import {TokenStorage} from "@/api/tokenStorage";
    import router from "../router";
    export default {
        name: "Login",
        data: () => ({
            valid: false,
            showPassword: false,
            username: "",
            password: "",
            rules: [
                v => !!v || 'Это поле обязательно'
            ]
        }),
        beforeMount() {
            let urlParams = window.location.search.replace( '?', '')
            if (urlParams === '' || urlParams === null) {
                // document.location.href = 'https://sso.spvkn.com/realms/advisability/protocol/openid-connect/auth?client_id=app-checker&response_type=code&redirect_uri=https://appschecker.website/login/&scope=openid&state=123'
                document.location.href = 'https://sso.spvkn.com/realms/advisability/protocol/openid-connect/auth?client_id=app-checker&response_type=code&redirect_uri=' + process.env.VUE_APP_KEYCLOAK_REDIRECT_URI + '&scope=openid&state=123'
                return
            }

            let url = '/users/auth?' + urlParams
            TokenStorage.clear()
            httpClient.get(url)
                .then((response) => {
                    TokenStorage.storeAccessToken(response.data.access);
                    TokenStorage.storeRefreshToken(response.data.refresh);
                    console.log('response login' + JSON.stringify(response));
                    router.push({name: 'Applications'})
                })
                .catch((error) => {
                    TokenStorage.clear();
                    console.log('response login error' + JSON.stringify(error))
                    this.$store.commit("showSnack", {
                        text: "Unable to authorize",
                        color: "red"
                    })
                })
        },
        methods: {
            login() {
                TokenStorage.clear()
                httpClient.post('/users/auth', {
                    username: this.username,
                    password: this.password
                })
                    .then((response) => {
                        TokenStorage.storeAccessToken(response.data.access);
                        TokenStorage.storeRefreshToken(response.data.refresh);
                        console.log('response login' + JSON.stringify(response));
                        router.push({name: 'Applications'})
                    })
                    .catch((error) => {
                        TokenStorage.clear();
                        console.log('response login error' + JSON.stringify(error))
                        this.$store.commit("showSnack", {
                            text: "Unable to authorize",
                            color: "red"
                        })
                    })
            }
        }
    }
</script>
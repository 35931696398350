<template>
    <v-sheet elevation="4" :color="application.ban_time ? 'red lighten-4' : ''">
        <v-container>
            <v-row>
                <v-col cols="auto" align-self="center">
                    <v-img
                        :src="application.icon"
                        style="border-radius: 10%"
                        width="80"
                        height="80"
                        aspect-ratio="1"/>
                </v-col>
                <v-col cols="3" class="d-flex flex-column justify-space-between ps-0">
                    <span class="font-weight-medium text-subtitle-1">{{ application.title }}</span>
                    <span class="font-weight-light text-body-2 text--secondary">
                        <v-icon>{{ application.store === "GP" ? "mdi-android" : "mdi-apple" }}</v-icon> <a :href=appLink
                                                                                                           target="_blank"
                                                                                                           class="text--secondary text-decoration-none">{{
                            application.package
                        }}</a>
                    </span>
                    <star-sheet :geoRating="application.ratings[0]"></star-sheet>
                </v-col>
                <v-col cols="2" class="align-self-center">
                    <v-row class="justify-center align-self-center" no-gutters>
                        <v-col cols="3"
                               v-for="(geo, index) in application.geos"
                               :key="index">
                            <v-chip small v-if="index < 12">
                                {{ geo.country_code }}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2" class="d-flex flex-column justify-space-around">
                    <div class="align-self-center"
                         v-for="(buyer, index) in application.buyers"
                         :key="index">
                        <template v-if="index < 2">
                            <v-avatar size="24" color="primary">
                                <img :src="buyer.photo">
                            </v-avatar>
                            <span class="text-body-2 ms-1 font-weight-medium">
                                {{ buyer.first_name }}
                            </span>
                        </template>
                    </div>
                    <!--                    <v-card outlined class="align-self-center">-->
                    <!--                        -->
                    <!--                    </v-card>-->
                    <!--                    <v-chip outlined pill class="align-self-center">-->
                    <!--                        <v-avatar left color="indigo">-->
                    <!--                            <span class="white&#45;&#45;text">NB</span>-->
                    <!--                        </v-avatar>-->
                    <!--                        <span>N Barmakova</span>-->
                    <!--                    </v-chip>-->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="text-caption text--secondary align-self-center"
                                v-bind="attrs"
                                v-on="on"
                                v-if="application.buyers.length > 2">+{{
                                    application.buyers.length - 2
                                }} more buyer{{ application.buyers.length === 3 ? '' : 's' }}
                            </span>
                        </template>
                        <v-col class="d-flex flex-column justify-space-around">
                            <v-row
                                   class="align-self-center"
                                   v-for="(buyer, index) in application.buyers.slice(2)"
                                   :key="index">
                                <v-avatar size="24" color="primary">
                                    <img :src="buyer.photo">
                                </v-avatar>
                                <span class="text-body-2 ms-1 font-weight-medium">
                                    {{ buyer.first_name }} {{ buyer.last_name }}
                                </span>
                            </v-row>
                        </v-col>
                    </v-tooltip>
                </v-col>
                <v-col cols="3" class="d-flex flex-column justify-space-around">
                    <span
                        v-if="application.ban_time !== null"
                        class="align-self-center text--secondary text-caption">
                        <b>Lifetime:</b> {{ formattedReleaseTime }}–{{ formattedBanTime }} ({{ lifetime }} days)
                    </span>
                    <template v-else>
                        <span class="align-self-center text--secondary text-caption">
                        <b>Release:</b> {{ formattedReleaseTime }} ({{ formattedDaysSinceRelease }})
                    </span>
                        <span class="align-self-center text--secondary text-caption">
                        <b>Update:</b> {{ formattedUpdateTime }} ({{ formattedDaysSinceUpdate }})
                    </span>
                    </template>
                </v-col>
                <v-col cols="auto" align-self="center">
                    <v-btn icon @click="editApplication">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

    </v-sheet>
</template>

<script>
import StarSheet from "@/views/applications/released/StarSheet";

const MS_PER_DAY = 1000 * 60 * 60 * 24
const FORMAT_OPTIONS = {year: '2-digit', month: '2-digit', day: '2-digit'};

export default {
    name: "application-component",
    components: {StarSheet},
    props: {
        application: {
            type: Object,
            required: true
        }
    },
    methods: {
        getDateWithoutTime(date) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        },
        isTheSameDay(date1, date2) {
            return this.getDateWithoutTime(date1) === this.getDateWithoutTime(date2)
        },
        dateTo2DigitsFormat(date) {
            return new Date(date).toLocaleDateString("ru-RU", FORMAT_OPTIONS)
        },
        countOfDaysBetweenDates(date1, date2) {
            const firstDate = this.getDateWithoutTime(date1)
            const secondDate = this.getDateWithoutTime(date2)
            return Math.floor((secondDate - firstDate) / MS_PER_DAY)
        },
        formatDaysSinceDate(date) {
            const releaseDate = new Date(date)
            const today = new Date()
            if (releaseDate.isTheSameDay) {
                return "today"
            }
            const diff = this.countOfDaysBetweenDates(releaseDate, today)
            if (diff === 1) {
                return "yesterday"
            }
            return diff + " days ago"
        },
        editApplication() {
            console.log("editApplication")
            this.$emit("settings-pressed")
        }
    },
    computed: {
        lifetime() {
            return this.countOfDaysBetweenDates(new Date(this.application.released), new Date(this.application.ban_time))
        },
        formattedReleaseTime() {
            return this.dateTo2DigitsFormat(this.application.released)
        },
        formattedUpdateTime() {
            return this.dateTo2DigitsFormat(this.application.last_update)
        },
        formattedBanTime() {
            return this.dateTo2DigitsFormat(this.application.ban_time)
        },
        formattedDaysSinceRelease() {
            return this.formatDaysSinceDate(this.application.released)
        },
        formattedDaysSinceUpdate() {
            return this.formatDaysSinceDate(this.application.last_update)
        },
        appLink() {
            return (this.application.store === "GP" ?
                "https://play.google.com/store/apps/details?id=" :
                "https://apps.apple.com/app/id") + this.application.package
        }
    }
}
</script>

<style lang="stylus" scoped>
//.v-chip.v-chip--small .v-avatar {
//    height: $chip-small-height !important;
//    width: $chip-small-height !important;
//}
</style>

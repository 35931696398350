import {httpClient} from "@/api/httpClient";
import store from "@/store/store";

export const PAGE_SIZE = 30

export const repository = new class Repository {
    fetchApplicationsForPage(page) {
        httpClient.get('/applications/', {
            params: {
                limit: PAGE_SIZE,
                offset: PAGE_SIZE * (page - 1),
                geo: store.state.application_filters.geo,
                store: store.state.application_filters.store,
                buyer: store.state.application_filters.buyer,
                banned: store.state.application_filters.is_banned,
                title: store.state.application_filters.application
            }
        })
            .then((response) => {
                // console.log("applications: " + JSON.stringify(response.data))
                store.commit("setApplications", {
                    applications: response.data.results,
                    count: response.data.count
                })
                // this.applications = response.data.results
                // this.next = response.data.next
                // this.previous = response.data.previous
            })
            .catch((error) => {
                // console.log("applications load error " + JSON.stringify(error));
                store.commit("showSnack", {
                    text: "Applications load error. " + error.message,
                    color: "red"
                })
            })
    }

    fetchModeratingApplicationsForPage(page) {
        httpClient.get('/applications/moderating', {
            params: {
                limit: PAGE_SIZE,
                offset: PAGE_SIZE * (page - 1),
                store: store.state.moderating_applications_filters.store,
                released: store.state.moderating_applications_filters.is_released,
                package: store.state.moderating_applications_filters.application
            }
        })
            .then((response) => {
                store.commit("setModeratingApplications", {
                    applications: response.data.results,
                    count: response.data.count
                })
            })
            .catch((error) => {
                store.commit("showSnack", {
                    text: "Moderating applications load error. " + error.message,
                    color: "red"
                })
            })
    }

    fetchApplication(applicationId) {
        return httpClient.get('/applications/' + applicationId + "/")
    }

    fetchModeratingApplication(applicationId) {
        return httpClient.get('/applications/moderating/' + applicationId + "/")
    }

    createApplication(requestData) {
        let promise = httpClient.post("/applications/", requestData)
        promise
            .then(response => {
                response
                this.fetchApplicationsForPage(store.state.page)
                store.commit("showSnack", {
                    text: "App has been successfully added!",
                    color: "green"
                })
            })
            .catch(error => {
                store.commit("showSnack", {
                    text: "App add returned an error: " + error.message,
                    color: "red"
                })
            })
        return promise
    }

    createModeratingApplication(requestData) {
        let promise = httpClient.post("/applications/moderating/", requestData)
        promise
            .then(response => {
                response
                this.fetchModeratingApplicationsForPage(store.state.moderating_page)
                store.commit("showSnack", {
                    text: "App has been successfully added!",
                    color: "green"
                })
            })
            .catch(error => {
                store.commit("showSnack", {
                    text: "App add returned an error: " + error.message,
                    color: "red"
                })
            })
        return promise
    }

    patchApplication(applicationId, requestData) {
        // console.log("patch application " + JSON.stringify(requestData))
        let promise = httpClient.patch("/applications/" + applicationId + "/", requestData)
        promise
            .then(response => {
                response
                this.fetchApplicationsForPage(store.state.page)
                store.commit("showSnack", {
                    text: "App has been successfully updated!",
                    color: "green"
                })
            })
            .catch(error => {
                console.log("app update error " + JSON.stringify(error))
                store.commit("showSnack", {
                    text: "App update returned an error: " + error.message,
                    color: "red"
                })
            })
        return promise
    }

    patchModeratingApplication(applicationId, requestData) {
        // console.log("patch application " + JSON.stringify(requestData))
        let promise = httpClient.patch("/applications/moderating/" + applicationId + "/", requestData)
        promise
            .then(response => {
                response
                this.fetchModeratingApplicationsForPage(store.state.moderating_page)
                store.commit("showSnack", {
                    text: "App has been successfully updated!",
                    color: "green"
                })
            })
            .catch(error => {
                console.log("app update error " + JSON.stringify(error))
                store.commit("showSnack", {
                    text: "App update returned an error: " + error.message,
                    color: "red"
                })
            })
        return promise
    }

    deleteApplication(applicationId) {
        let promise = httpClient.delete("/applications/" + applicationId + "/")
        promise
            .then(response => {
                response
                this.fetchApplicationsForPage(store.state.page)
                store.commit("showSnack", {
                    text: "App has been successfully deleted!",
                    color: "green"
                })
            })
            .catch(error => {
                store.commit("showSnack", {
                    text: "App delete returned an error: " + error.message,
                    color: "red"
                })
            })
        return promise
    }

    deleteModeratingApplication(applicationId) {
        let promise = httpClient.delete("/applications/moderating/" + applicationId + "/")
        promise
            .then(response => {
                response
                this.fetchModeratingApplicationsForPage(store.state.moderating_page)
                store.commit("showSnack", {
                    text: "App has been successfully deleted!",
                    color: "green"
                })
            })
            .catch(error => {
                store.commit("showSnack", {
                    text: "App delete returned an error: " + error.message,
                    color: "red"
                })
            })
        return promise
    }

    restoreApplication(applicationId) {
        let promise = httpClient.post("/applications/" + applicationId + "/restore-from-ban")
        promise
            .then(response => {
                response
                this.fetchApplicationsForPage(store.state.page)
                store.commit("showSnack", {
                    text: "App has been restored!",
                    color: "green"
                })
            })
            .catch(error => {
                let message
                if (error.response) {
                    console.log(JSON.stringify(error.response.data.message))
                    message = error.response.data.message
                } else {
                    message = "App restore returned an error: " + error.message
                }
                store.commit("showSnack", {
                    text: message,
                    color: "red"
                })
            })
        return promise
    }

    fetchGeos() {
        httpClient.get('/applications/geos', {params: {limit: 200}})
            .then(response => {
                store.commit("setGeos", response.data.results)
            })
    }

    fetchBuyers() {
        httpClient.get('/users/buyers', {params: {limit: 200}})
            .then(response => {
                store.commit("setBuyers", response.data.results.map(item => {
                    item.full_name = item.first_name + " " + item.last_name
                    return item
                }))
            })
    }

    fetchProfile() {
        httpClient.get('/users/profile')
            .then(response => {
                // console.log(JSON.stringify(response))
                store.commit("setUser", response.data)
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
    }

    generateTelegramUser() {
        httpClient.post('/notifications/telegram/generate-user')
            .then(response => {
                // console.log(JSON.stringify(response))
                store.commit("setTelegramUser", response.data)
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
    }

    checkTelegramIntegration() {
        httpClient.post('/notifications/telegram/update-notifications')
            .then(response => {
                response
                // console.log(JSON.stringify(response))
                this.fetchProfile()
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
    }
}

<template>
    <v-navigation-drawer
        right
        app>
        <v-container>
            <v-row class="flex-column">
                <v-col>
                    <v-btn block color="primary" @click="showAddApplicationDialog">
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        new application
                    </v-btn>
                </v-col>
                <v-divider></v-divider>
                <v-subheader>
                    <v-icon>mdi-filter-variant</v-icon>
                    Filters
                </v-subheader>
                <v-col>
                    <v-text-field
                        v-model="application_filters.application"
                        dense
                        clearable
                        label="Package">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="application_filters.store"
                        :items="stores"
                        dense
                        clearable
                        auto-select-first
                        label="Store"
                        item-value="abbr"
                        item-text="store"
                        placeholder="Choose the store">
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="application_filters.is_released"
                        :items="release_statuses"
                        dense
                        clearable
                        auto-select-first
                        label="Status"
                        item-value="state"
                        item-text="title"
                        placeholder="Choose the status">
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-btn block outlined color="primary" @click="filterApps">
                        filter
                    </v-btn>
                </v-col>
                <v-divider></v-divider>
                <v-subheader>
                    <v-icon>mdi-sort-variant</v-icon>
                    Sort
                </v-subheader>
                <v-col></v-col>
            </v-row>
        </v-container>
        <add-moderating-application-dialog :dialog.sync="dialogAddApplicationShown"></add-moderating-application-dialog>
    </v-navigation-drawer>
</template>

<script>
import {repository} from "@/api/repository";
import {stores} from "@/views/applications/stores";
import AddModeratingApplicationDialog from "@/views/applications/moderating/AddModeratingApplicationDialog";

export default {
    name: "moderation-applications-filter-nav-bar",
    components: {AddModeratingApplicationDialog},
    data() {
        return {
            dialogAddApplicationShown: false,
            release_statuses: [
                {
                    title: "Released",
                    state: true,
                },
                {
                    title: "Moderating",
                    state: false
                }
            ],
            stores: stores
        }
    },
    methods: {
        showAddApplicationDialog() {
            this.dialogAddApplicationShown = true
        },
        filterApps() {
            repository.fetchModeratingApplicationsForPage(1)
        },
        handleQueryParams() {
            console.log("handleQueryParams")
            let is_released = null
            if (this.$route.query.is_released === 'true') {
                is_released = true
            } else if (this.$route.query.is_released === 'false') {
                is_released = false
            } // otherwise it's null
            this.$store.commit("setModeratingFilterStore", this.$route.query.store || null)
            this.$store.commit("setModeratingFilterIsReleased", is_released)
        }
    },
    mounted() {
        this.handleQueryParams()
    },
    computed: {
        applications() {
            return this.$store.state.moderating_applications
        },
        application_filters() {
            return this.$store.state.moderating_applications_filters
        }
    },
    watch: {
        application_filters: {
            handler(filters) {
                // this just remove null-fields
                // eslint-disable-next-line no-unused-vars
                let filter_query_params = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null))
                this.$router.replace({
                    query: filter_query_params
                })
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./ModeratingApplicationComponent.vue?vue&type=template&id=6812984c&scoped=true&"
import script from "./ModeratingApplicationComponent.vue?vue&type=script&lang=js&"
export * from "./ModeratingApplicationComponent.vue?vue&type=script&lang=js&"
import style0 from "./ModeratingApplicationComponent.vue?vue&type=style&index=0&id=6812984c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6812984c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow,VSheet,VSpacer})

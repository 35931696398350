import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.indigo,
                accent: colors.lightBlue,
                background: colors.grey.lighten3
            }
        }
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});

<template>
    <v-dialog :value="dialog" @click:outside="closeDialog"
              max-width="700">
        <v-card>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="">
                <v-card-title>
                    Add new moderating application
                </v-card-title>
                <v-card-text>
                    <!--                <v-combobox-->
                    <!--                    v-model="packages"-->
                    <!--                    outlined-->
                    <!--                    chips-->
                    <!--                    label="Packages"-->
                    <!--                    multiple-->
                    <!--                    deletable-chips>-->
                    <!--                </v-combobox>-->
                    <v-select
                        v-model="form.store"
                        label="Store"
                        :items="stores"
                        item-text="store"
                        item-value="abbr"
                        outlined>
                    </v-select>
                    <v-text-field
                        v-model="form.appPackage"
                        :label="packageLabel"
                        :placeholder="packagePlaceholder"
                        required
                        :rules="rules.appPackage"
                        @change="appPackageErrors = []"
                        :error-messages="appPackageErrors"
                        outlined
                    ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!valid || loading"
                        :loading="loading"
                        color="primary"
                        @click="createApplication">
                        Create
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import {repository} from "@/api/repository";
import {googlePlayStore, stores} from "@/views/applications/stores";

const defaultForm = Object.freeze({
    appPackage: null,
    store: googlePlayStore
})

export default {
    name: "add-moderating-application-dialog",
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
    },
    data: () => ({
        valid: false,

        loading: false,
        appPackageErrors: [],

        stores: stores,

        form: Object.assign({}, defaultForm),

        rules: {
            appPackage: [val => (val || '').length > 0 || 'This field is required'],
        }
    }),
    methods: {
        closeDialog() {
            this.$emit("update:dialog", false)
        },
        createApplication() {
            const requestData = {
                package: this.form.appPackage,
                store: this.form.store
            }
            this.loading = true
            repository.createModeratingApplication(requestData)
                .then(response => {
                    response
                    this.loading = false
                    this.$refs.form.reset()
                    this.closeDialog()
                })
                .catch(error => {
                    if (error.response.data.package) {
                        this.appPackageErrors = [error.response.data.package]
                    }
                    this.loading = false
                    return error
                })
        },
    },
    computed: {
        packageLabel() {
            return this.form.store === googlePlayStore ? "Package" : "AppId"
        },
        packagePlaceholder() {
            return this.form.store === googlePlayStore ?
                "Enter the app package, for example 'com.google.chrome'" :
                "Enter the appId, for example '12345678'"
        }
    },
}
</script>

<style scoped>

</style>
<template>
    <v-dialog :value="dialog" @click:outside="closeDialog"
              max-width="700">
        <v-card>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="">
                <v-card-title>
                    Add new application
                </v-card-title>
                <v-card-text>
                    <!--                <v-combobox-->
                    <!--                    v-model="packages"-->
                    <!--                    outlined-->
                    <!--                    chips-->
                    <!--                    label="Packages"-->
                    <!--                    multiple-->
                    <!--                    deletable-chips>-->
                    <!--                </v-combobox>-->
                    <v-select
                        v-model="form.store"
                        label="Store"
                        :items="stores"
                        item-text="store"
                        item-value="abbr"
                        outlined>
                    </v-select>
                    <v-text-field
                        v-model="form.appPackage"
                        :label="packageLabel"
                        :placeholder="packagePlaceholder"
                        required
                        :rules="rules.appPackage"
                        @change="appPackageErrors = []"
                        :error-messages="appPackageErrors"
                        outlined
                    ></v-text-field>
                    <v-autocomplete
                        v-model="form.geos"
                        :items="geos"
                        outlined
                        chips
                        label="Geos"
                        placeholder="Enter list of geos"
                        hint="Might be empty"
                        multiple
                        item-text="country_code"
                        item-value="id"
                        deletable-chips>
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeGeo(data.item)">
                                {{ data.item.country_code }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.country_code }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.country_title }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.buyers"
                        :items="buyers"
                        outlined
                        chips
                        label="Buyers"
                        placeholder="Enter list of buyers"
                        multiple
                        item-text="last_name"
                        item-value="id"
                        deletable-chips>
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removeBuyer(data.item)">
                                <v-avatar left>
                                    <v-img :src="data.item.photo"></v-img>
                                </v-avatar>
                                {{ data.item.first_name }} {{ data.item.last_name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar>
                                    <img :src="data.item.photo">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.first_name }} {{
                                            data.item.last_name
                                        }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.username }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <span>
                        <input type="checkbox" id="jack" v-model="form.is_competitor"> <b>Competitor</b>
                    </span>
                    <br>
                    <span class="subheading">Minimal rating to alert in <v-icon small>mdi-slack</v-icon>Slack</span>
                    <v-row dense>
                        <v-col>
                            <v-chip-group
                                v-model="form.ratingForAlert">
                                <v-chip
                                    active-class="primary"
                                    v-for="rating in predefinedRatings"
                                    :key="rating"
                                    :value="rating">
                                    {{ rating }} ★
                                </v-chip>
                            </v-chip-group>
                        </v-col>
                        <v-col cols="auto" class="mt-3 me-2">
                            <span>or</span>
                        </v-col>
                        <v-col>
                            <v-text-field
                                class="mt-1"
                                dense
                                v-model="form.ratingForAlert"
                                label="Rating"
                                :rules="rules.ratingForAlert"
                                outlined>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!valid || loading"
                        :loading="loading"
                        color="primary"
                        @click="createApplication">
                        Create
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import {repository} from "@/api/repository";
import {googlePlayStore, stores} from "@/views/applications/stores";

const predefinedRatings = [4.5, 4.0, 3.5, 3.0, 2.5, 2.0]


const defaultForm = Object.freeze({
    appPackage: null,
    buyers: [],
    geos: [],
    ratingForAlert: null,
    store: googlePlayStore,
    is_competitor: false
})

export default {
    name: "add-application-dialog",
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
    },
    data: () => ({
        valid: false,

        loading: false,
        appPackageErrors: [],

        predefinedRatings: predefinedRatings,
        stores: stores,

        form: Object.assign({}, defaultForm),

        rules: {
            appPackage: [val => (val || '').length > 0 || 'This field is required'],
            ratingForAlert: [
                val => (val || '').length === 0 || (!isNaN(val) && val <= 5 && val >= 1) || 'Must be a number between 1 and 5, for example 3.42'
            ],
        }
    }),
    methods: {
        closeDialog() {
            this.$emit("update:dialog", false)
        },
        createApplication() {
            const requestData = {
                package: this.form.appPackage,
                buyers: this.form.buyers,
                store: this.form.store,
                rating_to_slack_alert: this.form.ratingForAlert,
                geos: this.form.geos,
                is_competitor: this.form.is_competitor,
            }
            this.loading = true
            repository.createApplication(requestData)
                .then(response => {
                    response
                    this.loading = false
                    this.$refs.form.reset()
                    this.closeDialog()
                })
                .catch(error => {
                    if (error.response.data.package) {
                        this.appPackageErrors = [error.response.data.package]
                    }
                    this.loading = false
                    return error
                })
        },
        removeBuyer(buyer) {
            this.form.buyers.splice(this.form.buyers.indexOf(buyer.id), 1)
        },
        removeGeo(geo) {
            this.form.geos.splice(this.form.geos.indexOf(geo.id), 1)
        },
    },
    mounted() {
        repository.fetchGeos()
        repository.fetchBuyers()
    },
    computed: {
        geos() {
            return this.$store.state.geos
        },
        buyers() {
            return this.$store.state.buyers
        },
        packageLabel() {
            return this.form.store === googlePlayStore ? "Package" : "AppId"
        },
        packagePlaceholder() {
            return this.form.store === googlePlayStore ?
                "Enter the app package, for example 'com.google.chrome'" :
                "Enter the appId, for example '12345678'"
        }
    },
    // computed: {
    //     formIsValid() {
    //         return this.form.appPackage
    //     },
    // }
}
</script>

<style scoped>

</style>
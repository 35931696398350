<template>
    <v-sheet class="align-self-start" :color="getStarColor(geoRating)" rounded align="start">
        <span class="ms-1 me-1 text-subtitle-2 white--text font-weight-regular">{{ getRating(geoRating) }} ★
        </span>
    </v-sheet>
</template>

<script>
export default {
    name: "star-sheet",
    props: {
        geoRating: {
            type: Object
        },
    },
    methods: {
        getStarColor() {
            if (!this.geoRating || this.geoRating.rating == null || this.geoRating.rating < 1) {
                return "grey"
            }
            let rating = this.geoRating.rating
            if (rating >= 4.5) {
                return "green"
            }
            if (rating >= 4) {
                return "light-green"
            }
            if (rating >= 3.5) {
                return "amber"
            }
            if (rating >= 3) {
                return "orange"
            }
            if (rating >= 2) {
                return "deep-orange"
            }
            return "red"
        },
        getRating() {
            if (!this.geoRating || !this.geoRating.rating) {
                return "—"
            }
            return this.geoRating.rating.toFixed(2)
        }
    }
}
</script>

<style scoped>

</style>

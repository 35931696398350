<template>
    <v-container class="pt-0">
        <v-row dense>
            <!--            <v-col class="pa-0">-->
            <!--                <v-autocomplete-->
            <!--                    class="pb-0"-->
            <!--                    :items="applications"-->
            <!--                    dense-->
            <!--                    hide-details-->
            <!--                    clearable-->
            <!--                    solo-->
            <!--                    auto-select-first-->
            <!--                    label="Search by application"-->
            <!--                    placeholder="Choose the application"-->
            <!--                    item-text="title"-->
            <!--                    item-value="id">-->
            <!--                    <template v-slot:item="data">-->
            <!--                        <v-list-item-avatar>-->
            <!--                            <img :src="data.item.icon">-->
            <!--                        </v-list-item-avatar>-->
            <!--                        <v-list-item-content>-->
            <!--                            <v-list-item-title>{{ data.item.title }} {{-->
            <!--                                    data.item.last_name-->
            <!--                                }}-->
            <!--                            </v-list-item-title>-->
            <!--                            <v-list-item-subtitle>{{-->
            <!--                                    data.item.package-->
            <!--                                }}-->
            <!--                            </v-list-item-subtitle>-->
            <!--                        </v-list-item-content>-->
            <!--                    </template>-->
            <!--                </v-autocomplete>-->
            <!--            </v-col>-->
            <template v-if="alive_applications.length !== 0">
                <v-col cols="12" class="pa-0">
                    <v-subheader>Alive apps</v-subheader>
                </v-col>
                <v-col
                    cols="12"
                    v-for="(application, index) in alive_applications"
                    :key="'alive_' + index">
                    <application-component
                        :application="application"
                        v-on:settings-pressed="navigateToEditApplication(application)">
                    </application-component>
                </v-col>
            </template>

            <template v-if="banned_applications.length !== 0">
                <v-col cols="12" class="pa-0">
                    <v-subheader>Banned apps</v-subheader>
                </v-col>
                <v-col
                    cols="12"
                    v-for="(application, index) in banned_applications"
                    :key="'banned_' + index">
                    <application-component
                        :application="application"
                        v-on:settings-pressed="navigateToEditApplication(application)">
                    </application-component>
                </v-col>
            </template>
            <template v-if="applications.length === 0">
                <v-col
                    cols="12"
                    class="my-4">
                    <span class="text--secondary text-h5 justify-center d-flex">No apps</span>
                </v-col>
            </template>
            <v-col>
                <v-pagination
                    class="mt-4"
                    v-model="page"
                    :length="pages">
                </v-pagination>
            </v-col>
        </v-row>
        <edit-application-dialog
            :application="dialogEditApplication"
            :dialog.sync="dialogEditApplicationShown">
        </edit-application-dialog>
    </v-container>
</template>

<script>
import ApplicationComponent from "@/views/applications/released/ApplicationComponent";
import {PAGE_SIZE, repository} from "@/api/repository";
import EditApplicationDialog from "@/views/applications/released/EditApplicationDialog";
import router from "@/router";

export default {
    name: "Application",
    components: {ApplicationComponent, EditApplicationDialog},
    data() {
        return {
            page: 1,
            dialogEditApplicationShown: false,
            dialogEditApplication: null
        }
    },
    computed: {
        applications() {
            return this.$store.state.applications
        },
        pages() {
            return Math.ceil(this.$store.state.applications_count / PAGE_SIZE)
        },
        alive_applications() {
            return this.$store.state.applications.filter(application => {
                return application.ban_time === null
            })
        },
        banned_applications() {
            return this.$store.state.applications.filter(application => {
                return application.ban_time !== null
            })
        },
    },
    methods: {
        navigateToEditApplication(application) {
            router.push({name: 'ApplicationEdit', params: {id: application.id}})
        },
        editApplication(application) {
            this.dialogEditApplication = Object.assign({}, application)
            this.dialogEditApplication.buyers = this.dialogEditApplication.buyers.map((e) => e.id)
            this.dialogEditApplication.geos = this.dialogEditApplication.geos.map((e) => e.id)
            this.dialogEditApplicationShown = true
        },
        handleDialogOpen() {
            if (this.$route.params.id !== undefined) {
                repository.fetchApplication(this.$route.params.id).then((response) => {
                    this.editApplication(response.data)
                }).catch((error) => {
                    console.log(JSON.stringify(error))
                })
            }
        }
    },
    mounted() {
        repository.fetchApplicationsForPage(this.$store.state.page)
        this.handleDialogOpen()
    },
    watch: {
        $route(v) {
            v
            this.handleDialogOpen()
        },
        page: function(page) {
            this.$store.commit("setPage", page)
            repository.fetchApplicationsForPage(page)
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <v-container style="max-width: 960px">
        <v-row class="flex-column" dense>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                <v-img class="rounded" aspect-ratio="1" :src="user.photo"></v-img>
                            </v-col>
                            <v-col cols="9">
                                <v-row class="flex-column" dense>
                                    <v-col>
                                        <span class="text-h5 text--primary">
                                            {{ user.first_name }} {{ user.last_name }}
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <v-icon small>mdi-email</v-icon>
                                        <span>
                                            {{ user.email }}
                                        </span>
                                    </v-col>
                                    <v-col>
                                        <v-icon small>mdi-send</v-icon>
                                        <template v-if="isTelegramIntegrationCompleted">
                                            <span>
                                                Telegram connected: {{ telegramVisibleName }}
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span>
                                                No Telegram
                                            </span>
                                            <v-btn small text color="primary" @click="showTelegramBindDialog">
                                                Bind
                                            </v-btn>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <telegram-integration-dialog
            :dialog.sync="dialogTelegramBindShown"
            :telegram_user="user.telegram_user">
        </telegram-integration-dialog>
    </v-container>
</template>

<script>
import TelegramIntegrationDialog from "@/views/profile/TelegramIntegrationDialog";

export default {
    name: "Profile",
    components: {
        TelegramIntegrationDialog
    },
    data() {
        return {
            dialogTelegramBindShown: false
        }
    },
    methods: {
        showTelegramBindDialog() {
            this.dialogTelegramBindShown = true
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        isTelegramIntegrationCompleted() {
            return this.user.telegram_user !== null && this.user.telegram_user.chat_id !== null
        },
        telegramVisibleName() {
            if (this.user.telegram_user.username != null) {
                return "@" + this.user.telegram_user.username
            }
            if (this.user.telegram_user.last_name != null) {
                return this.user_telegram_user.first_name + " " + this.user_telegram_user.last_name
            }
            return this.user_telegram_user.first_name
        }
    }
}
</script>

<style scoped>

</style>
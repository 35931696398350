import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
        snackbar: {
            text: "",
            color: "",
        },
        applications: [],
        applications_count: 0,
        page: 1,
        moderating_applications: [],
        moderating_applications_count: 0,
        moderating_page: 1,
        buyers: [],
        geos: [],
        application_filters: {
            application: null,
            store: null,
            geo: null,
            buyer: null,
            is_banned: null,
        },
        moderating_applications_filters: {
            application: null,
            store: null,
            is_released: null,
        },
        user: null
    },
    mutations: {
        showSnack(state, payload) {
            state.snackbar = {
                text: payload.text,
                color: payload.color || "primary"
            }
        },
        setApplications(state, { applications, count }) {
            state.applications = applications
            state.applications_count = count
        },
        setModeratingApplications(state, { applications, count }) {
            state.moderating_applications = applications
            state.moderating_applications_count = count
        },
        setPage(state, page) {
            state.page = page
        },
        setModeratingPage(state, page) {
            state.moderating_page = page
        },
        setBuyers(state, buyers) {
            state.buyers = buyers
        },
        setGeos(state, geos) {
            state.geos = geos
        },
        setFilterStore(state, store) {
            state.application_filters.store = store
        },
        setFilterIsBanned(state, is_banned) {
            state.application_filters.is_banned = is_banned
        },
        setFilterBuyer(state, buyer) {
            state.application_filters.buyer = buyer
        },
        setFilterGeo(state, geo) {
            state.application_filters.geo = geo
        },
        setModeratingFilterStore(state, store) {
            state.moderating_applications_filters.store = store
        },
        setModeratingFilterIsReleased(state, is_released) {
            state.moderating_applications_filters.is_released = is_released
        },
        setUser(state, user) {
            state.user = user
        },
        setTelegramUser(state, telegram_user) {
            state.user.telegram_user = telegram_user
        }
    }
})

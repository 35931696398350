import axios from 'axios'
import {TokenStorage} from './tokenStorage'
import Promise from 'es6-promise'
import router from "../router";

const BASE_URL = '/api/';
const REFRESH_TOKEN_ROUTE = '/users/token/refresh';
const API_VERSION = "1.0"

let httpClientInstance = axios.create({
    baseURL: BASE_URL,
    params: {
        version: API_VERSION
    }
});

httpClientInstance.interceptors.request.use(
    (config) => {
        if (TokenStorage.isAuthenticated()) {
            config.headers['Authorization'] = 'Bearer ' + TokenStorage.getAccessToken()
            // config.headers = {
            //     'Authorization': 'Bearer ' + TokenStorage.getAccessToken()
            // }
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
);

httpClientInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        // const originalRequest = error.config;
        if (error.response.status === 401 && !error.config.url.endsWith(REFRESH_TOKEN_ROUTE)) {
            router.push({name: "Login"});
            return Promise.reject(error);
            // received 401 error and we do not have auth credits, so we should redirect to Login page
            // if (!TokenStorage.isAuthenticated()) {
            //     console.log("401 error, not authenticated. Redirect to login page");
            //     router.push({name: "Login"});
            //     return Promise.reject(error);
            // }
            // console.log("refresh " + TokenStorage.getRefreshToken());
            // return httpClientInstance.post(REFRESH_TOKEN_ROUTE, {refresh: TokenStorage.getRefreshToken()})
            //     .then((response) => {
            //         console.log("Access token was successfully updated");
            //         TokenStorage.storeAccessToken(response.data.access);
            //         return httpClient(originalRequest)
            //     })
            //     .catch((error) => {
            //         console.log("error caught, " + error.response.status)
            //         if (error.response.status === 401) {
            //             console.log("Unable to refresh token. Logout");
            //             TokenStorage.clear();
            //             router.push({name: "Login"});
            //         }
            //     })
        }
        return Promise.reject(error)
    }
);

httpClientInstance.interceptors.request.use(request => {
    // console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
})

httpClientInstance.interceptors.response.use(response => {
    // console.log('Response:', JSON.stringify(response, null, 2))
    return response
})

export const httpClient = httpClientInstance;

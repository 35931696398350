import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Applications from "@/views/applications/released/Applications";
import NavigationLayout from "@/layout/NavigationLayout";
import ApplicationsFilterNavBar from "@/views/applications/released/ApplicationsFilterNavBar";
import Profile from "@/views/profile/Profile";
import ModeratingApplications from "@/views/applications/moderating/ModeratingApplications";
import ModeratingApplicationsFilterNavBar from "@/views/applications/moderating/ModeratingApplicationsFilterNavBar";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/applications'
    },
    {
        path: '/login',
        name: 'Login',
        components: {default: Login}
    },
    {
        path: '/applications',
        name: 'Applications',
        components: {
            default: Applications,
            navigation: NavigationLayout,
            navigationRight: ApplicationsFilterNavBar
        }
    },
    {
        path: '/applications/moderating',
        name: 'ModeratingApplications',
        components: {
            default: ModeratingApplications,
            navigation: NavigationLayout,
            navigationRight: ModeratingApplicationsFilterNavBar
        }
    },
    {
        path: '/applications/:id',
        name: 'ApplicationEdit',
        components: {
            default: Applications,
            navigation: NavigationLayout,
            navigationRight: ApplicationsFilterNavBar
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        components: {
            default: Profile,
            navigation: NavigationLayout
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
